<template>
  <div class="crud-create-external-wrapper">
    <div class="admin-view-toolbar" style="margin-bottom: 40px">
      <div class="flex flex-between margin-l-bottom">
        <div class="start-side flex flex-between gap-s">
          <form-button
            icon="check"
            theme="success"
            :show-slot="false"
            :uk-tooltip="translateTitleCase('core.crud.createSaveTooltip')"
            @click="save"></form-button>

          <form-button
            icon="plus"
            theme="primary"
            class="uk-margin-small-right"
            :show-slot="false"
            :uk-tooltip="translateTitleCase('core.crud.createAndNewTooltip')"
            @click="saveAndNew"></form-button>

          <form-button
            icon="copy"
            type="primary"
            :show-slot="false"
            :uk-tooltip="translateTitleCase('core.crud.createCopyTooltip')"
            @click="saveAndCopy"></form-button>
        </div>
        <div class="end-side"></div>
      </div>
    </div>

    <div class="crud-create-content">
      <base-form
        ref="createForm"
        v-model="item"
        :action="formAction"
        method="post"
        :form-path="formPath"
        :config="{ context: 'create' }"
        @form:submitSuccess="afterSubmit"></base-form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    formPath: {
      type: String,
      default: false,
    },
    formAction: {
      type: String,
      default: false,
    },
    successRedirect: {
      type: Object,
      default: { name: "admin-dashboard" },
    },
  },
  data: function () {
    return {
      item: {},
    };
  },
  computed: {},
  methods: {
    // successful submit handler
    afterSubmit: function (result) {
      this.$saffron.ui.notification("core.crud.createdSuccess", "success");
    },
    // save button - trigger submit. after normal submit action, if successfull, go back to list
    save: async function () {
      let result;

      try {
        result = await this.$refs.createForm.handleSubmit();
      } catch (e) {
        return;
      }

      if (result.isError) {
        this.$s.ui.notification("core.genericError", "error");
        return;
      }
      if (result) {
        this.$router.push(this.successRedirect);
      }
    },
    saveAndNew: async function () {
      let result;

      try {
        result = await this.$refs.createForm.handleSubmit();
      } catch (e) {
        return;
      }

      if (result) {
        this.$refs.createForm.clear();
      }
    },
    saveAndCopy: async function () {
      let result;

      try {
        result = await this.$refs.createForm.handleSubmit();
      } catch (e) {
        return;
      }

      if (result) {
        this.$saffron.ui.notification("core.crud.saveAndCopySuccess", "success");
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
